<template lang="">
    <div class="max-w-6xl w-full mx-auto grid grid-cols-4">

        <leftSideBar :tags="tags" />

        <main-blog :posts="posts"/>

        <RightSideBar />

    </div>
    
    <bottom-navigation class="md:hidden" :tags="tags"/>
</template>
<script>
import { mapGetters } from 'vuex';
import leftSideBar from './components/left-side-bar.vue';
import mainBlog from './components/main-blog.vue';
import RightSideBar from './components/right-side-bar.vue';
import BottomNavigation from './components/bottom-navigation.vue';

export default {
    name: 'blog-index',
    components: {
        leftSideBar,
        mainBlog,
        RightSideBar,
        BottomNavigation
    },
    data: {

    },
    computed: {
        ...mapGetters({
            tags: 'blogs/tags',
            posts: 'blogs/posts'
        }),
    },
    created() {
        this.$store.dispatch('blogs/tagData');
        this.$store.dispatch('blogs/postData');
    },
}
</script>

<style lang="">
    
</style>
