<template lang="">
    <div class="w-full col-span-4 md:col-span-1 md:max-h-screen md:sticky md:top-16 overflow-y-auto order-1">
        <div class="flex items-center justify-center ">
            <div class="flex my-4 md:my-0">
                <input type="text" v-model="searchKeyword" @input="search" class="px-4 py-2 w-full rounded-l-full font-sans" placeholder="Search...">
                <button class="px-4 text-white bg-gray-600 rounded-r-full font-sans">
                    Search
                </button>
            </div>
        </div>

        <div class="md:block hidden">
            <div class="flex justify-between">
                <p class="mt-10 font-semibold">All Tags</p>
                <button class="mt-10 font-semibold text-red-500 underline" @click="SeeAllPosts()">See All Posts</button>
            </div>
            
            <div class="flex flex-col mt-2">
                <button class="bg-gray-300 p-2 rounded-md my-0.5 text-left" @click="TagSearch(tag.name)" v-for="tag in tags">{{ tag.name }}</button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'left-side-bar',
    components: {

    },
    data() {
        return {
            searchKeyword: '',
        }
    },
    props: {
        tags: {
            type: Object,
            default: {}
        }
    },
    methods: {
        search() {
            this.$store.dispatch('blogs/postData', {
                params: {
                    search: this.searchKeyword
                },
            });
        },
        TagSearch(tag_name) {
            this.$store.dispatch('blogs/postData', {
                params: {
                    tag: tag_name
                },
            });
        },
        SeeAllPosts() {
            this.$store.dispatch('blogs/postData', {
                params: {
                    search: ''
                },
            });
        }
    }
}
</script>

<style lang="">
    
</style>
