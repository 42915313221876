<template lang="">
    <div class="col-span-2 hidden md:block md:col-span-1 max-h-screen md:sticky md:top-16 overflow-y-auto order-2 md:order-3">
        <div class="items-center justify-center rounded-md pb-4">
            <div class="flex flex-col items-center">
                <div>
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-24 w-24" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z"/>
                    </svg>
                </div>
                <div>
                    <p class="text-xl font-semibold">{{ $store.getters["student/name"] }}</p>
                </div>
            </div>
        </div>
        
        <div class="items-center justify-center rounded-md bg-gray-200 mb-2">
            <router-link to="/my/blog/profile" class="flex p-1">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 mt-1 ml-1" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-3 7h3m-3 4h3m-6-4h.01M9 16h.01" />
                </svg>

                <span class="p-1.5 text-sm">Your All Post</span>
            </router-link>
        </div>

        <div class="items-center justify-center rounded-md bg-gray-200 mb-2">
            <router-link to="/my/create-blog" class="flex p-1">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 mt-1 ml-1.5" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z" />
                </svg>
                <span class="p-1.5 text-sm">Write your post. </span>
            </router-link>
        </div>
    </div>
</template>


<script>
import CreateBlog from './create-blog.vue';

export default {
    name: 'right-side-bar',
    components: {
        CreateBlog
    },
    data() {
        return {
            searchKeyword: '',
        }
    },
    methods: {
        myAllPost(user_id) {
            this.$store.dispatch('blogs/postData', {
                params: {
                    user_id: user_id
                },
            });
        }
    }
}
</script>

<style lang="">
    
</style>